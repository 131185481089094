import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
    canActivate: [],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'client',
    loadChildren: () =>
      import('./modules/client/client.module').then((m) => m.ClientModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'balance',
    loadChildren: () =>
      import('./modules/balance-statement/balance-statement.module').then(
        (m) => m.BalanceStatementModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'pix',
    loadChildren: () =>
      import('./modules/pix/pix.module').then((m) => m.PixModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'comprovantes',
    loadChildren: () =>
      import('./modules/receipt-list/receipt-list.module').then(
        (m) => m.ReceiptListModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./modules/deposit-money/deposit-money.module').then(
        (m) => m.DepositMoneyModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'transfer',
    loadChildren: () =>
      import('./modules/transfer/transfer.module').then(
        (m) => m.TransferModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'payments',
    loadChildren: () =>
      import('./modules/payments/payments.module').then(
        (m) => m.PaymentsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'approvals',
    loadChildren: () =>
      import('./modules/approvals/approvals.module').then(
        (m) => m.ApprovalsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account/account.module').then((m) => m.AccountModule),
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
